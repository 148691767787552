import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import bootstrap from 'bootstrap'

// Lightbox
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'

// Elements
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent';
import { Helmet } from 'react-helmet'


const LightboxWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`
interface GalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Inspiration
}

const Inspiration: React.FC<GalleryProps> = ({ fields }) => {
  const [openIndex, setOpenIndex] = React.useState<null | number>(null)
  const images: any = []

  fields.item?.forEach((field) => {    
    images.push({
      url: field?.image?.localFile?.childImageSharp?.original?.src,
    })
  })

  return (
    <>
      <Helmet>
      <script 
        src="https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js" 
        integrity="sha384-GNFwBvfVxBkLMJpYMOABq3c+d3KnQxudP/mGPkzpZSTYykLBNsZEnG2D9G/X/+7D" 
        crossOrigin="anonymous" 
        async 
      />
      </Helmet>
      <section>
        <div className="container py-5 my-lg-5">
          <div className="row" data-masonry='{"percentPosition": true }'>
            {fields.item?.map((grid: any, index: number) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className='col-6 col-sm-4 col-md-3 mb-4'
                >
                <div>
                  <Grid item={grid} index={index} setOpenIndex={setOpenIndex} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <AnimatePresence>
          {openIndex !== null && (
            <LightboxWrapper
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{
                duration: 0.2,
              }}
            >
              <Lightbox
                images={images}
                startIndex={openIndex}
                onClose={() => {
                  setOpenIndex(null)
                }}
                doubleClickZoom={2}
                showTitle={false}
              />
            </LightboxWrapper>
          )}
        </AnimatePresence>
      </section>
    </>
  )
}

const ColImageDesc = styled.div`
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: ${({ theme }) => theme.color.primary};
`

const ColImage = styled(Plaatjie)`
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
`

const Col = styled.div`
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &:hover ${ColImageDesc} {
    opacity: 1;
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
`

interface GridProps {
  item: any
  index: number
  // eslint-disable-next-line no-unused-vars
  setOpenIndex: (index: number) => void
}

const Grid: React.FC<GridProps> = ({ item, index, setOpenIndex }) => (
  <Col
    role="button"
    className="position-relative"
    onClick={() => {
      setOpenIndex(index)
    }}
  >
    <ColImage className="w-100 h-100" image={item.image} alt="VT Tuinen" />
      {item.description && (
        <ColImageDesc className="py-2 text-center">
          <Content content={item.description} />
        </ColImageDesc>
      )}
  </Col>
)

export default Inspiration
